import underConstruction from './under-construction.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <img src={underConstruction} className="App-logo" alt="Under Contruction Sign" />
        <p>
          You can reach me at: dconcha <i>at</i> gmail <i> dot </i> com
        </p>
      </header>
    </div>
  );
}

export default App;
